import React, { useState, useEffect } from "react";
import "../styles/Landing.css";
import successIcon from "../Assets/imgs/successIcon.png";
import mainImg from "../Assets/imgs/main-img.webp";
import zongLogo from "../Assets/imgs/zong-logo-img.webp";
type Props = {
  otp: {
    one: string;
    two: string;
    three: string;
    four: string;
  };
  handleChangeOTP: (e: any, index: any) => void;
  handleBackspace: (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => void;
  onResendOtpPressed: () => void;
  inputRef: React.RefObject<HTMLInputElement>[];

  showLoader: boolean;
  errorMsg: string;
  showError: boolean;
  onSubscribePressed: () => void;
  phoneNo: string;
  handlePhoneNoChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  seconds: number;
  validNum: boolean;
  isHidden: boolean;
  handleOverLay: () => void;
  package_id: string | null;
  isHidden2: boolean;
  handleOverLay2: () => void;
  pricePoint: string;
  serviceName: string;
};
export const Landing = (props: Props) => {
  const { one, two, three, four } = props.otp;

  // privacy modal
  const [isShow, setIsPrivacyModalOpen] = useState(false);
  const privacyModal = () => {
    setIsPrivacyModalOpen(!isShow);
  };
  // privacy modal

  return (
    // <div className="lm-landingpage">
    <div className="">
      {/* <button onClick={toggleModal}>Open Modal</button> */}
      {props.isHidden === false && (
        <>
          <div
            className="modal-overlay"
            onClick={() => props.handleOverLay()}
          ></div>

          <div className="modal">
            <button
              className="close-modal"
              onClick={() => props.handleOverLay()}
            >
              X
            </button>
            <div className="modal-content">
              <img src={successIcon} alt="icon" />
              <p>You are successfully subscribed to WEBDOC Pakistan.</p>
              <p> Please call 7030 for more details.</p>
            </div>
          </div>
        </>
      )}
      {props.isHidden2 === false && (
        <>
          <div
            className="modal-overlay"
            onClick={() => props.handleOverLay2()}
          ></div>

          <div className="modal">
            <button
              className="close-modal"
              onClick={() => props.handleOverLay2()}
            >
              X
            </button>
            <div className="modal-content">
              <img src={successIcon} alt="icon" />
              <p>You're Already Subscribed to Bima Pakistan.</p>
              <p> Please call (042) 111 119 878 for more details.</p>
            </div>
          </div>
        </>
      )}
      <div className="dm-section">
        <div className="main-content">
          <img
            src={mainImg}
            alt="sports"
            width={345}
            height={333}
            className="lp-main-image"
          />
          <div className="lp-absolute-content">
            <div className="numberdiv">
              <div className="zong-logo-img">
                <img
                  src={zongLogo}
                  alt="Zong-logo-img"
                  width={126}
                  height={40}
                />
              </div>
              <p className="landingtext">Enter Your Mobile Number</p>

              <div className="input-wraper">
                <span>+92</span>
                <input
                  className="numberinput"
                  type="number"
                  placeholder="3XX - XXXXXXX"
                  max={10}
                  value={props.phoneNo}
                  onChange={(e) => {
                    props.handlePhoneNoChange(e);
                  }}
                />
              </div>
            </div>
            {/* otp div */}
            {props.showError && <p className="error">{props.errorMsg}</p>}
            {/* <p className="error">error message</p> */}

            <div className="otpDiv">
              {props.phoneNo.length === 10 && props.validNum ? (
                <>
                  <p className="landingtext">Enter OTP to Verify</p>
                  <div className="otpinputWraper">
                    <input
                      type="tel"
                      name="one"
                      maxLength={1}
                      autoFocus
                      value={props.otp.one}
                      onChange={(e) => {
                        props.handleChangeOTP(e, 0);
                      }}
                      ref={props.inputRef[0]}
                      onKeyDown={(e) => {
                        props.handleBackspace(e, 0);
                      }}
                    />
                    <input
                      type="tel"
                      name="two"
                      maxLength={1}
                      value={props.otp.two}
                      onChange={(e) => {
                        props.handleChangeOTP(e, 1);
                      }}
                      ref={props.inputRef[1]}
                      onKeyDown={(e) => {
                        props.handleBackspace(e, 1);
                      }}
                    />
                    <input
                      type="tel"
                      name="three"
                      maxLength={1}
                      value={props.otp.three}
                      onChange={(e) => {
                        props.handleChangeOTP(e, 2);
                      }}
                      ref={props.inputRef[2]}
                      onKeyDown={(e) => {
                        props.handleBackspace(e, 2);
                      }}
                    />
                    <input
                      type="tel"
                      name="four"
                      maxLength={1}
                      value={props.otp.four}
                      onChange={(e) => {
                        props.handleChangeOTP(e, 3);
                      }}
                      ref={props.inputRef[3]}
                      onKeyDown={(e) => {
                        props.handleBackspace(e, 3);
                      }}
                    />
                  </div>

                  {/* <div className="invalidotp">Invalid OTP entered</div> */}
                  <div className="otpcounter">
                    <p>
                      {props.seconds === 0 ? (
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={props.onResendOtpPressed}
                        >
                          Resend Otp
                        </a>
                      ) : null}
                      {props.seconds > 0 && (
                        <>
                          <>Resend 00:{props.seconds}</>
                          {props.seconds === 0 && (
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={props.onResendOtpPressed}
                            >
                              Resend Otp
                            </a>
                          )}
                        </>
                      )}
                    </p>
                  </div>

                  {props.showLoader ? (
                    <div className="subscribe-btn">
                      <button>Loading...</button>
                    </div>
                  ) : (
                    <>
                      {one && two && three && four ? (
                        <div className="subscribe-btn">
                          <button onClick={props.onSubscribePressed}>
                            Subscribe Now
                          </button>
                        </div>
                      ) : (
                        <div className="subscribe-btn">
                          <button>Subscribe Now</button>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : null}

              <div className="policttext">
                <p>
                  {props.serviceName} Insurance Only For. {props.pricePoint} +
                  Tax daily
                  {/* Mobile Insurance Only For. 4.5 + Tax daily */}
                </p>

                {/* {props.package_id === "1" ? (
                  <p>Mobile Insurance Only For. 4.5 + Tax daily</p>
                ) : (
                  <>
                    {props.package_id === "2" ? (
                      <p>Crop Insurance Only For. 7 + Tax daily</p>
                    ) : (
                      <>
                        {props.package_id === "3" ? (
                          <p>Home Insurance Only For. 12 + Tax daily</p>
                        ) : (
                          <>
                            {props.package_id === "4" ? (
                              <p>
                                Accidental Health Insurance Only For. 4 + Tax
                                daily
                              </p>
                            ) : (
                              <>
                                {props.package_id === "5" ? (
                                  <p>Bike Insurance Only For. 6 + Tax daily</p>
                                ) : (
                                  <>
                                    {props.package_id === "6" ? (
                                      <>
                                        <p>
                                          Car Insurance Only For. 12 + Tax daily
                                        </p>
                                      </>
                                    ) : (
                                      <p>
                                        Health Insurance Only For. 7 + Tax daily
                                      </p>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )} */}
              </div>

              <div className="terms-wraper">
                <a
                  href="/privacy-policy"
                  target="_blank"
                  className="privacypolicy"
                >
                  Privacy Policy
                </a>
                {props.package_id !== "2" && (
                  <>
                    <span className="dot"></span>
                    <a
                      href={
                        props.package_id === "1" || props.package_id !== "3"
                          ? "https://www.zong.com.pk/vas/mobile-insurance-product-details"
                          : "https://www.zong.com.pk/vas/health-insurance-product-details"
                      }
                      target="_blank"
                      className="privacypolicy"
                    >
                      Terms & Conditions
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Landing;
